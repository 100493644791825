import React, { useRef, useLayoutEffect } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

//Styles
import { two_col_blk, image, content } from '../content/TwoColBlock.module.css'

gsap.registerPlugin(ScrollTrigger)

export default function TwoColBlock(props) {

  // Set GSAP Ref
  let el = useRef();
  const element1 = useRef();
  const element2 = useRef();

  // Init useLayoutEffect Hook
  useLayoutEffect(() => {

    //Set GSAP Selector Utility
    const q = gsap.utils.selector(el);

    // GSAP Refs
    const wrapper = el.current;
    const elements = [
      element1.current,
      element2.current,
    ];

    // Animate Elements from then To + scrollTrigger

    gsap.set(q(elements), {
      y: 50,
      opacity:.15,
      autoAlpha: .15
    });

    gsap.to(q(elements), {
        y: 0, 
        opacity: 1,
        stagger: .1,
        overwrite: 'auto',
        scrollTrigger: {
          trigger: wrapper,
          start: "top bottom-=200px",  
          end: 'bottom-=200px',
          scrub: 1,
        }
      }
    );

    // Kill GSAP Animation scrollTrigger
    return () => ScrollTrigger.getAll().forEach(t => t.kill());

  }, [])

  // Set BG Image Props
  let bgImg = {
    backgroundImage: `url(${ props.image })`,
  };

  return (

    <div ref={el} className={ two_col_blk }>

      <div className={ image }>
        <div ref={ element1 } style={ bgImg }></div>
      </div>

      <div className={`wrapper ${ content }`}>
        <div ref={ element2 }>
          <h2 className={ props.headStyle ? `${ props.headStyle }` : '' }>{ props.title }</h2>
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
      </div>
      
  </div>

  )
}