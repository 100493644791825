import React, { useRef, useLayoutEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

// Images
import wavesImg from "../../../images/bigWave.svg"

// Styles
import { bigWave } from "../wavesBackground/BigWave.module.css"

// Init ScrollTrigger
gsap.registerPlugin(ScrollTrigger)

export default function BigWave() {
  //Set GSAP Ref
  let el = useRef()
  let wave1 = useRef()
  let wave2 = useRef()

  useLayoutEffect(() => {
    // GSAP Selector Utility
    const q = gsap.utils.selector(el)

    // Tie GSAP elements to Ref
    const wrapper = el.current
    const waves = [wave1.current, wave2.current]

    // GSAP Timeline for individual wave SVG animations
    var tl = gsap.timeline({ repeat: -1 })
    tl.to(wave1.current, {
      backgroundPosition: "-1800px 0px",
      duration: 60,
      ease: "none",
    })
    tl.to(
      wave2.current,
      { backgroundPosition: "-3600px 0px", duration: 60, ease: "none" },
      0
    )

    // Animate Elements From
    gsap.from(q(waves), {
      autoAlpha: 1,
      opacity: 1,
      duration: 1,
    })

    // Animate Element To then scrollTrigger
    gsap.to(q(waves), {
      autoAlpha: 0,
      opacity: 0,
      y: 50,
      stagger: 0.5,
      immediateRender: false,
      scrollTrigger: {
        anticipatePin: 1,
        trigger: wrapper,
        start: "bottom bottom-=1px",
        pin: "._pin",
        end: "+=200",
        scrub: 0.5,
      },
    })

    // Kill GSAP Animations & scrollTrigger
    return () => {
      tl.kill()
      ScrollTrigger.getAll().forEach(t => t.kill())
    }
  }, [])

  return (
    <div ref={el} className={bigWave}>
      <div className="_pin">
        <div ref={wave1} style={{ backgroundImage: `url(${wavesImg})` }}></div>
        <div ref={wave2} style={{ backgroundImage: `url(${wavesImg})` }}></div>
      </div>
    </div>
  )
}
