import React, { useRef, useLayoutEffect } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

// Init ScrollTrigger
gsap.registerPlugin(ScrollTrigger)

export default function FullWidthBlock(props) {

  //Set GSAP ref
  let el = useRef();

  // Init useLayoutEffect Hook
  useLayoutEffect(() => {

    //Set GSAP Selector Utility
    const q = gsap.utils.selector(el);
    
    // Tie GSAP wrapper to Ref
      const wrapper = el.current;

    // Animate in Title Element from 
      gsap.from(q(".item-anim"), { 
        opacity: 0,
        autoAlpha: 0,
        duration:1,
        y: -20,
      });

    // Animate Title Element from
      gsap.fromTo(q(".item-anim"), {
        opacity:1,
        y:0
      },

    // Animate Title Element to + scrollTrigger
      {
        y: -50,
        opacity: 0,
        scrollTrigger: {
          trigger: wrapper,
          start:'top top',
          end: 'center center-=200px',
          scrub: true,
        },
      });

      // Kill GSAP Animation scrollTrigger
      return () => ScrollTrigger.getAll().forEach(t => t.kill());

  }, [])

  return (

    <div ref={ el } className='lonely_heading'>
      <h1 className='item-anim'>Get away from it all with our day boat hire, Tiger Lily.</h1>
    </div>

  )
}

