import * as React from "react"

//Data Hook
import { useHireQuery } from "../hooks/useHireQuery"

//Components
import Layout from "../components/layout/Layout"
import FullWidthBlock from "../components/content/FullWidthBlock"
import TwoColBlock from "../components/content/TwoColBlock"
import LonelyHeading from "../components/content/LonelyHeading"
import BigWave from "../components/staticAnimations/wavesBackground/BigWave"

export default function BoatHire() {
  //WP Data
  const data = useHireQuery()

  return (
    <Layout>
      <BigWave />

      <div className="wrapper sub_page">
        <LonelyHeading />
      </div>

      <div className="wrapper">
        <FullWidthBlock
          content="Sail away from it all and enjoy the wonders of the famous mile-long tunnel at Foulridge, and perhaps sample the food and drink at the historic Anchor Inn at Salterforth."
          fancyBgText="Tiger Lilly"
        />
      </div>

      {data.wpPage.contentBlocks.block.map(({ title, content, image }) => (
        <TwoColBlock
          headStyle="special"
          title={title}
          key={title}
          content={content}
          image={image.mediaItemUrl}
        />
      ))}

      <div className="wrapper">
        <FullWidthBlock
          title="Day Hire, Only £150"
          linkTo="/contact/"
          linkText="Get in touch"
          fancyBgText="Book Now"
        />
      </div>
    </Layout>
  )
}

export function Head() {
  return (
    <>
      <title>Boat Hire | Lower Park Marina</title>
      <meta
        name="description"
        content="Why not hire our day boat, Tiger Lily?"
      />
    </>
  )
}
