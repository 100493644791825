import React, { useRef, useLayoutEffect } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

// Components
import Button from '../elements/Button'

// Hooks
import { useArrayRef } from '../../hooks/useArrayRef'

//Styles
import { full_width_block, content, big_text, brandBg, fancy_bg_text } from '../content/FullWidthBlock.module.css'

// Init ScrollTrigger
gsap.registerPlugin(ScrollTrigger)

export default function FullWidthBlock(props) {

//Set GSAP Ref
  let el = useRef();
  let fancyText = useRef();
  const [refs, setRef] = useArrayRef();

// Init useLayoutEffect Hook
  useLayoutEffect(() => {

//Set GSAP Selector Utility
    const q = gsap.utils.selector(el);

// Tie GSAP wrapper to Ref
    const wrapper = el.current;

// Animate Wrapper Ref from 
    gsap.from(wrapper, { opacity:0, y:-20, duration:1 });

// Set Fancy Text default Styles
    gsap.set(q(fancyText.current), { 
      opacity: .2
    });

// Animate Fancy Text to + scrollTrigger out
    gsap.to(q(fancyText.current), {
      y: -50,
      opacity: 0,
      immediateRender: false,
      overwrite: 'auto',
      scrollTrigger: {
        trigger: wrapper,
        start: "top+=200px center", 
        scrub: .5,
      }
    });

// Animate Stagger Child Elements to + scrollTrigger out
    gsap.to(q(refs.current), {
      opacity: 0,
      immediateRender: false,
      overwrite: 'auto',
      stagger: {
        each: 0.5,
        from: "top",
      },
      scrollTrigger: {
        trigger: wrapper,
        start: "top+=200px center", 
        scrub: .5,
      }
    });

// Kill GSAP Animation scrollTrigger
  
    return () => {
      
      ScrollTrigger.getAll().forEach(t => t.kill());
    }

    }, [ refs ])

    //? <div className={ props.background ? `fancy-text has_brand_bg ${ fancy_bg_text }` : `fancy-text ${ fancy_bg_text }` } >

  return (

    <div className={ full_width_block }>
      <div ref={el} className={ props.background ? `content_container ${ `${props.background}_overrides` }` : 'content_container' }>

        {props.fancyBgText 
          ? <div 
              ref={ fancyText } 
              className={`${fancy_bg_text} ` + (props.background ? 'has_brand_bg' : '')} 
            >
              <div>{ props.fancyBgText }</div>
            </div> 
          : <React.Fragment/> 
        }

        { props.title 
          ? <h2 
              ref={setRef} 
              className={ props.headStyle ? ` ${ props.headStyle }` : ''} 
            >
              { props.title }
            </h2> 
          : <React.Fragment/> 
        }

        { props.content 
          ? <p 
              ref={setRef} 
              className={ content }
            >
              { props.content }
            </p>  
          : <React.Fragment/>
        }
        
        { props.bigText 
          ? <span 
              ref={setRef} 
              className={ big_text }
            >
              { props.bigText }
            </span> 
          : <React.Fragment/> 
        }

        { props.linkTo 
          ? <div ref={setRef}>
              <Button 
                linkTo={props.linkTo} 
                title={props.linkText ? props.linkText : 'Find out more' } 
                linkText={props.linkText ? props.linkText : 'Find out more' }/> 
            </div>
          : <React.Fragment/> 
        }

      </div>

        { props.background 
          ? <div className={ brandBg }></div> 
          : <React.Fragment/>
        }

    </div>

  )
}


